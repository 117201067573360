import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StyleToggleService {
    private toggleSubject = new BehaviorSubject<boolean>(false);
    toggle$ = this.toggleSubject.asObservable();

    activate(): void {
        this.toggleSubject.next(true);
    }

    deactivate(): void {
        this.toggleSubject.next(false);
    }
}
