<ng-container *ngIf="publicEvent">
    <div
        class="event"
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxFlex="100"
        fxFlex.lt-sm="50"
        [attr.orientation]="orientation"
        [attr.clickable]="clickable"
    >
        <!-- ======== Vertical orientation card ========= -->
        <div *ngIf="isVerticalOrientation" fxLayout="column" class="content">
            <!-- Uncomment when TAGS are implemented
     <div class="head" fxLayout="row" fxLayoutAlign="space-between center">
       <div fxLayout="row" fxLayoutAlign="start center" style="padding: 16px;">
         <vsc-icon style="margin-right: 8px">menu</vsc-icon>
         <h3 style="margin: 0">ARTS</h3>
       </div>
       <img src="" />
 </div> -->
            <div class="image-container">
                <img
                    class="image"
                    [src]="publicEvent.imageUrl | imageUrlSize: 'small'"
                    [alt]="'Image' | transloco"
                />
            </div>

            <div class="body" fxFlex fxLayout="column" fxLayoutAlign="start">
                <div
                    class="main"
                    fxFlex
                    fxLayout="column"
                    fxLayoutAlign="space-between"
                >
                    <!-- Title and Organizer -->

                    <div class="title-and-organizer mx-16 mt-16 mb-20">
                        <!-- Title -->
                        <div class="title">
                            <!-- <vsc-icon>camera</vsc-icon>  -->
                            <h2>{{ publicEvent.title }}</h2>
                        </div>
                        <!-- /Title -->

                        <!-- Organizer -->
                        <div
                            class="organizer mt-5"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                        >
                            <i>{{ 'with' | transloco }} </i
                            ><span class="organizer-name">{{
                                publicEvent.organizer
                            }}</span>

                            <!-- Livestream tag -->
                            <div
                                class="livestream-text"
                                *ngIf="publicEvent.isLiveStream"
                            >
                                <vsc-icon icon="circle-play"></vsc-icon>
                                <span>{{ 'Live stream' | transloco }}</span>
                            </div>
                            <!-- /Livestream tag -->
                        </div>
                        <!-- /Organizer -->
                    </div>

                    <!-- /Title and Organizer -->

                    <div
                        class="time-logo-box"
                        fxLayout="column"
                        fxLayoutAlign="center"
                    >
                        <div
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                        >
                            <!-- Date and time -->
                            <div
                                class="date-time pl-16 py-16"
                                fxLayout="row"
                                fxLayoutAlign="start center"
                                fxFlex="50"
                                [ngSwitch]="
                                    dateStatusHelper(
                                        publicEvent.startDateTime,
                                        publicEvent.duration
                                    )
                                "
                            >
                                <!-- happening now -->
                                <div
                                    class="happening-now"
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    *ngSwitchCase="0"
                                >
                                    <vsc-icon icon="clock" iconStyle="light">
                                    </vsc-icon>
                                    <span>{{
                                        'Happening now!' | transloco
                                    }}</span>
                                </div>
                                <!-- /happening now -->

                                <!-- happening in one hour -->
                                <div
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    *ngSwitchCase="1"
                                >
                                    <vsc-icon icon="clock" iconStyle="light">
                                    </vsc-icon>
                                    <ng-container
                                        *ngLet="
                                            leftMinutes$ | async as leftMinutes
                                        "
                                    >
                                        <span
                                            >{{ 'Starts in' | transloco }}
                                            {{ leftMinutes }}
                                            {{
                                                leftMinutes > 1
                                                    ? ('minutes' | transloco)
                                                    : ('minute' | transloco)
                                            }}</span
                                        >
                                    </ng-container>
                                </div>
                                <!-- /happening in one hour -->

                                <!-- happening later -->
                                <div
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    *ngSwitchCase="2"
                                >
                                    <vsc-icon icon="clock" iconStyle="light">
                                    </vsc-icon>
                                    {{
                                        publicEvent.startDateTime
                                            | localizedTime
                                    }}
                                    -
                                    {{
                                        publicEvent.startDateTime
                                            | localizedTime
                                                : publicEvent.duration
                                                : 'minutes'
                                    }}
                                </div>
                                <!-- /happening later -->
                            </div>
                            <!-- /Date and time -->
                            <div
                                class="actions p-0"
                                fxFlex="50"
                                *ngIf="verticalCardActionsTemplateRef"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="
                                        verticalCardActionsTemplateRef
                                    "
                                ></ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ======== /Vertical orientation card ========= -->

        <!-- ======== Horizontal orientation card ========= -->
        <div *ngIf="!isVerticalOrientation" class="content">
            <div class="wrapper" fxLayout="row">
                <div class="image-container">
                    <img
                        class="image"
                        [src]="publicEvent.imageUrl | imageUrlSize: 'small'"
                        [alt]="'Event image' | transloco"
                    />
                </div>

                <div class="body" fxFlex>
                    <div class="main" fxFlex fxLayout="row" fxLayoutGap="20px">
                        <div fxFlex="60" fxLayout="column" class="info">
                            <!-- Title and Organizer -->
                            <div
                                fxFlex
                                fxLayout="column"
                                fxLayoutAlign="start start"
                                class="title-organizer"
                            >
                                <!-- Title -->
                                <div
                                    class="title"
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                >
                                    <img
                                        *ngIf="publicEvent.isClassRecorded"
                                        class="mr-7"
                                        [matTooltip]="
                                            'This program will be recorded.'
                                                | transloco
                                        "
                                        aria-label="This program will be recorded."
                                        src="assets/images/recorded.svg"
                                        alt="recorded icon"
                                    />

                                    <h2 class="p-0">{{ publicEvent.title }}</h2>
                                </div>
                                <!-- /Title -->

                                <!-- Organizer -->
                                <div
                                    class="organizer mt-5"
                                    fxLayout="row"
                                    fxLayoutAlign="start start"
                                >
                                    <i>{{ 'with' | transloco }} </i
                                    ><span>{{ publicEvent.organizer }}</span>
                                </div>
                                <!-- /Organizer -->
                            </div>
                            <!-- /Title and Organizer -->

                            <!-- Date and time and live stream indicator -->
                            <div fxLayout="column" fxLayoutAlign="center">
                                <div
                                    fxLayout="row"
                                    fxLayoutAlign="start center"
                                    class="date-time-live-stream-container"
                                >
                                    <!-- Date and time -->
                                    <div
                                        class="date-time mr-20"
                                        [ngSwitch]="
                                            dateStatusHelper(
                                                publicEvent.startDateTime,
                                                publicEvent.duration
                                            )
                                        "
                                    >
                                        <!-- happening now -->
                                        <div
                                            class="happening-now"
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            *ngSwitchCase="0"
                                        >
                                            <vsc-icon
                                                icon="clock"
                                                iconStyle="light"
                                            >
                                            </vsc-icon>
                                            <span>{{
                                                'Happening now!' | transloco
                                            }}</span>
                                        </div>
                                        <!-- /happening now -->

                                        <!-- happening in one hour -->
                                        <div
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            *ngSwitchCase="1"
                                        >
                                            <vsc-icon
                                                icon="clock"
                                                iconStyle="light"
                                            >
                                            </vsc-icon>
                                            <ng-container
                                                *ngLet="
                                                    leftMinutes$
                                                        | async as leftMinutes
                                                "
                                            >
                                                <span
                                                    >{{
                                                        'Starts in' | transloco
                                                    }}
                                                    {{ leftMinutes }}
                                                    {{
                                                        leftMinutes > 1
                                                            ? ('minutes'
                                                              | transloco)
                                                            : ('minute'
                                                              | transloco)
                                                    }}</span
                                                >
                                            </ng-container>
                                        </div>
                                        <!-- /happening in one hour -->

                                        <!-- happening later -->
                                        <div
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            *ngSwitchCase="2"
                                        >
                                            <vsc-icon
                                                icon="clock"
                                                iconStyle="light"
                                            >
                                            </vsc-icon>
                                            {{
                                                publicEvent.startDateTime
                                                    | localizedTime
                                            }}
                                            -
                                            {{
                                                publicEvent.startDateTime
                                                    | localizedTime
                                                        : publicEvent.duration
                                                        : 'minutes'
                                            }}
                                        </div>
                                        <!-- /happening later -->
                                    </div>
                                    <!-- /Date and time -->

                                    <!-- Live stream -->
                                    <div
                                        *ngIf="publicEvent.isLiveStream"
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        class="date-time"
                                    >
                                        <vsc-icon icon="circle-play"></vsc-icon>
                                        <span>{{
                                            'Live stream' | transloco
                                        }}</span>
                                    </div>
                                    <!-- /Live stream -->
                                </div>
                            </div>
                            <!-- /Date and time and live stream indicator -->
                        </div>

                        <div
                            fxFlex
                            fxLayout="column"
                            fxLayoutAlign="space-between end"
                            class="side-box"
                        >
                            <!-- Logo -->
                            <div
                                class="logo"
                                fxLayout="row"
                                fxLayoutAlign="start end"
                            >
                                <img
                                    *ngIf="publicEvent.groupLogoUrl"
                                    [src]="publicEvent.groupLogoUrl"
                                    [alt]="'Logo' | transloco"
                                />
                            </div>
                            <!-- /Logo -->

                            <div
                                *ngIf="horizontalCardActionsTemplateRef"
                                class="actions"
                                fxFlex
                                fxLayout="row"
                                fxLayoutAlign="end end"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="
                                        horizontalCardActionsTemplateRef
                                    "
                                ></ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ======== /Horizontal orientation card ========= -->
    </div>
</ng-container>
