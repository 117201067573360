<div fxLayout="column" fxLayoutGap="4px" class="weather-container">
    <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="weather-title-cont"
    >
        <div
            fxLayout="column"
            fxLayoutAlign="start center"
            class="weather-title-cont"
        >
            <h1 class="mat-h1 no-margins weather-header">
                {{ 'Showing weather for your current location' | transloco }}
            </h1>
        </div>
    </div>
    <div class="weather mat-card" fxLayout="row" fxFlex="100">
        <div class="weather-full-width-cont">
            <ng-container *ngIf="weather$ | async as weather; else loading">
                <div fxLayout="column" class="item-container">
                    <!-- current day detailed info -->
                    <div
                        fxLayout="row"
                        fxLayoutAlign="center"
                        class="current-weather-cont"
                    >
                        <div
                            fxLayout="column"
                            class="current-weather-icon"
                            fxLayoutAlign="center"
                        >
                            <img
                                class="weather-current-icon"
                                alt="Weather icon"
                                [src]="
                                    'assets/images/weather/' +
                                    weather[0]?.current.icon +
                                    '.svg'
                                "
                            />
                        </div>
                        <div
                            fxLayout="column"
                            class="current-weather-info"
                            fxLayoutAlign="center"
                        >
                            <div class="second-col-cont">
                                <!-- add element here so it can be aligned with the second col -->
                                <p class="weather-current-font">
                                    {{
                                        weather[0]?.day
                                            | localizedDate: 'dddd, MMMM D'
                                    }}
                                </p>
                                <h1 class="current-temp-cont">
                                    {{
                                        Math.round(
                                            weather[0]?.current.temperature
                                        )
                                    }}
                                    °F
                                </h1>
                                <p class="max-temp-cont">
                                    {{ 'Max' | transloco }}:
                                    <span class="fw-600"
                                        >{{
                                            Math.round(weather[0]?.temp.max)
                                        }}
                                        °F</span
                                    >
                                </p>
                                <p class="min-temp-cont">
                                    {{ 'Min' | transloco }}:
                                    <span class="fw-600">
                                        {{ Math.round(weather[0]?.temp.min) }}
                                        °F</span
                                    >
                                </p>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center">
                            <div class="third-col-cont">
                                <p class="precipitation-cont">
                                    {{ 'Pressure' | transloco }}:
                                    <span class="fw-600">
                                        {{
                                            Math.round(
                                                weather[0]?.current.pressure
                                            )
                                        }}
                                        mb</span
                                    >
                                </p>
                                <p class="wind-cont">
                                    {{ 'Wind' | transloco }}:
                                    <span class="fw-600">
                                        {{
                                            Math.round(
                                                weather[0]?.current.windSpeed
                                            )
                                        }}
                                        MPH
                                    </span>
                                </p>
                                <p class="humidity-cont">
                                    {{ 'Humidity' | transloco }}:
                                    <span class="fw-600">
                                        {{ weather[0]?.current.humidity }} %
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- forecasting part -->
                    <div
                        class="forecasting-part"
                        fxLayout="row"
                        fxLayoutAlign="space-around"
                    >
                        <div
                            fxFlex="14.28"
                            fxLayout="column"
                            fxLayoutAlign="space-around center"
                            *ngFor="let weatherData of weather | slice: 1"
                            class="item weather-item-cont"
                        >
                            <p class="weather-font mt-0 mb-10">
                                {{ weatherData.day | localizedDate: 'dddd' }}
                            </p>
                            <img
                                class="weather-icon mb-10"
                                alt="Weather icon"
                                [src]="
                                    'assets/images/weather/' +
                                    weatherData.weather[0].icon +
                                    '.svg'
                                "
                            />
                            <h3 class="mb-0 mt-5 fw-400">
                                {{ Math.round(weatherData.temp.max) }} °F
                            </h3>
                            <h4 class="mb-0 mt-10 fw-400 secondary-color">
                                {{ Math.round(weatherData.temp.min) }} °F
                            </h4>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #loading>
                <div class="loading">
                    {{ 'Loading weather data ...' | transloco }}
                </div>
            </ng-template>
        </div>
    </div>
</div>
