<button
    [matMenuTriggerFor]="menu"
    [matTooltip]="'Change Organization' | transloco"
    class="btn-tenant"
>
    <vsc-icon class="mr-8" color="accent">sitemap</vsc-icon>
    <span>{{ activeTenantName$ | async }}</span>
</button>

<mat-menu #menu="matMenu" class="mat-menu">
    <button
        *ngFor="let tenant of availableTenants$ | async"
        mat-menu-item
        (click)="change(tenant.id)"
    >
        <span [style.font-weight]="activeTenant === tenant.id ? 'bold' : null">
            {{ tenant.name }}</span
        >
    </button>
</mat-menu>
